<template>
  <div v-if="singleData._id" :key="updateKey" class="settings">
    <div class="row">
      <div class="col-lg-9 mb-4">
        <LabelInput
          class="mb-4"
          font-family="'Oswald', sans-serif"
          :base-card="true"
          :label="labels.title"
          :value="singleData.title"
          @changed="inputChanged($event, 'title')"
        />
        <div class="message-editor">
          <TextEditorCard
            :title="labels.text"
            :value="singleData.text"
            @changed="inputChanged($event.value, 'text')"
          />
          <Button
            v-if="
              !typeof singleData.published == 'object' ||
              (singleData.published &&
                !Object.keys(singleData.published).length)
            "
            class="publish-button"
            :label="labels.send_message"
            variant="btn-purple"
            :disabled="!singleData.text.length"
            :styles="{ padding: '8px 15px', fontWeight: 700 }"
            :action="() => publishAction()"
          />
          <font-awesome-icon
            v-else
            class="times-circle fa-2x"
            icon="times-circle"
            @click="publishAction"
          />
        </div>
      </div>
      <div class="col-lg-3 mb-4">
        <LabelButtonCard class="settings__cards" :list="labelButtonList" />
        <BaseCard class="checkbox mt-4" :base-card="true">
          <FormulateInput
            type="checkbox"
            v-model="stateSingleData.hasNotes"
            :label="labels.hasNotes"
            @change="saveGeneralSettings()"
          />
          <Button
            :label="labels.notes"
            variant="btn-orange"
            :disabled="!stateSingleData.hasNotes"
            :styles="{ padding: '8px 15px', fontWeight: 700 }"
            :action="() => goToNotes()"
          />
        </BaseCard>
      </div>
      <ArchiveCard
        class="col-12"
        :labels="labels"
        :title="singleData.title"
        @archive="archiveMessageFunction"
        :type="labels.message"
      />
    </div>
    <router-view
      name="message_settings_notes"
      :route-back="{ name: 'message-settings' }"
    />
  </div>
</template>

<script>
import LabelInput from "@/components/Input/LabelInput";
import TextEditorCard from "@/components/Input/TextEditorCard";
import LabelButtonCard from "@/components/Cards/LabelButtonCard";
import BaseCard from "@/components/Cards/BaseCard";
import Button from "@/components/Buttons/Button";
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPaperPlane, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import ArchiveCard from "@/components/Cards/ArchiveCard";

library.add(faPaperPlane, faTimesCircle);

export default {
  name: "Settings",
  components: {
    LabelInput,
    TextEditorCard,
    LabelButtonCard,
    BaseCard,
    Button,
    ArchiveCard,
  },
  data() {
    return {
      updateKey: Date.now(),
    };
  },
  computed: {
    ...mapState("message", {
      singleData: "singleData",
      stateSingleData: "stateSingleData",
    }),
    ...mapGetters("helper", ["labels"]),
    labelButtonList() {
      return [
        { labels: [this.labels.last_change, this.createDate("last_change")] },
        { labels: [this.labels.created, this.createDate("created")] },
        {
          labels: [
            this.labels.last_change_by,
            this.singleData.lastChanged.author.username,
          ],
        },
        {
          labels: [
            this.labels.created_by,
            this.singleData.created.author.username,
          ],
        },
      ];
    },
  },
  methods: {
    ...mapActions("notification", {
      addNotification: "addNotification",
    }),
    ...mapActions("message", {
      updateMessage: "update",
      togglePublish: "togglePublish",
      archiveMessage: "archive",
    }),
    ...mapMutations("message", {
      setPreUpdateData: "setPreUpdateData",
    }),
    goToNotes() {
      if (this.$route.name !== "message-settings-notes") {
        this.$router.push({ name: "message-settings-notes" });
      }
    },
    saveGeneralSettings() {
      const finalData = {
        id: this.singleData._id,
        payload: this.stateSingleData,
      };

      this.updateMessage(finalData)
        .then((res) => {
          if (res.success) {
            this.addNotification({
              variant: "success",
              msg: ["saved"],
              labels: this.labels,
              dismissSecs: 5,
            });
          } else if (res.error) {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    createDate(type) {
      if (type === "last_change") {
        return `${this.singleData.lastChanged.date?.slice(
          0,
          10
        )} ${this.singleData.lastChanged.date?.slice(11, 16)}`;
      }

      return `${this.singleData.created.date?.slice(
        0,
        10
      )} ${this.singleData.created.date?.slice(11, 16)}`;
    },
    inputChanged(value, key) {
      let messageUpdateData = { ...this.stateSingleData };

      if (messageUpdateData[key]) messageUpdateData[key] = value;

      this.setPreUpdateData(messageUpdateData);
    },
    publishAction() {
      let payload = {
        id: this.singleData._id,
        action: true,
      };

      if (
        typeof this.singleData.published == "object" &&
        Object.keys(this.singleData.published).length
      )
        payload.action = false;

      this.togglePublish(payload)
        .then((res) => {
          if (res.success) {
            if (payload.action)
              this.addNotification({
                variant: "success",
                msg: this.labels.published_message,
                dismissSecs: 5,
              });

            if (!payload.action)
              this.addNotification({
                variant: "success",
                msg: this.labels.unpublished_message,
                dismissSecs: 5,
              });
          } else if (res.error) {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    archiveMessageFunction() {
      this.archiveMessage({ id: this.singleData._id })
        .then((res) => {
          if (res.success) {
            this.$router.push({ name: "messages" });
          }
        })
        .catch((er) => {
          console.log(er);
        });
    },
  },
  watch: {
    singleData() {
      this.updateKey = Date.now();
    },
  },
};
</script>

<style lang="scss" scoped>
.settings {
  width: 100%;
  padding: 20px;
  .publish-button {
    right: 0;
    bottom: 0;
    margin-right: 1rem;
    margin-bottom: 1rem;
    position: absolute;
  }
  .message-editor {
    position: relative;
    .times-circle {
      right: 0;
      bottom: 0;
      margin-right: 1rem;
      margin-bottom: 1rem;
      position: absolute;
      border-radius: 50%;
      border: 1px solid $slateblue;
      background: $slateblue;
      color: $white;
      padding: 0.5rem;
      cursor: pointer;
    }

    .times-circle {
      border: 1px solid red;
      background: red;
      padding: 4px;
    }
  }
}
::v-deep {
  .formulate-input .formulate-input-element {
    max-width: 100%;
    label {
      margin-bottom: 0;
    }
  }

  .settings__cards {
    padding: 0;

    &.label-button-card {
      padding: 0;
    }
  }
}
</style>
